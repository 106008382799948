import { onMounted, onUnmounted, ref } from 'vue';

export const useContentScrolledObserver = (element, elementTop) => {
    const isContentScrolled = ref(false);
    const observer = ref<IntersectionObserver | null>(null);

    const setupObserver = () => {
        if (observer.value) {
            observer.value.disconnect();
        }

        if (!element.value || !elementTop.value) {
            return;
        }

        observer.value = new IntersectionObserver(([entry]) => {
            isContentScrolled.value = !entry.isIntersecting;
        }, {
            root: element.value,
            threshold: 0,
        });

        observer.value.observe(elementTop.value);
    };

    onMounted(setupObserver);

    onUnmounted(() => {
        if (observer.value) {
            observer.value.disconnect();
        }
    });

    return {
        isContentScrolled,
    };
};
