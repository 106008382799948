import { onMounted, onUnmounted, ref } from 'vue';

export const useContentBottomObserver = (element, elementBottom) => {
    const isBottomReached = ref(false);
    const observer = ref<IntersectionObserver | null>(null);

    const setupObserver = () => {
        if (observer.value) {
            observer.value.disconnect();
        }

        if (!element.value || !elementBottom.value) {
            return;
        }

        observer.value = new IntersectionObserver(([entry]) => {
            isBottomReached.value = entry.isIntersecting;
        }, {
            root: element.value,
            threshold: 0,
        });

        observer.value.observe(elementBottom.value);
    };

    onMounted(setupObserver);

    onUnmounted(() => {
        if (observer.value) {
            observer.value.disconnect();
        }
    });

    return {
        isBottomReached,
    };
};
