<template>
    <Teleport to="body">
        <div class="base-modal"
             :class="{
                 'base-modal__overlay': showBackgroundOverlay,
                 'base-modal--video-theme': ThemesEnum.VIDEO === theme,
                 'base-modal--game-theme': ThemesEnum.GAME === theme,
                 'base-modal--notification-theme': ThemesEnum.NOTIFICATION === theme,
             }">
            <div ref="modalContainerRef"
                 class="base-modal__container"
                 :style="containerStyle">
                <BaseCloseButton v-if="isClosable"
                                 class="base-modal__close-button"
                                 @click="handleModalClose" />
                <template v-if="$slots.header">
                    <div class="base-modal__header">
                        <slot name="header" />
                    </div>
                </template>
                <div v-if="$slots.content"
                     ref="modalContentRef"
                     class="base-modal__content"
                     :class="{
                         'base-modal__content--top-shadow': hasScrollTopShadow,
                         'base-modal__content--top-shadow--active': hasScrollTopShadow && isContentScrolled,
                     }"
                     :style="{ overflow: contentOverflow }">
                    <div ref="modalContentTopRef" />
                    <slot name="content" />
                    <div ref="modalContentBottomRef"
                         class="base-modal__bottom-ref"/>
                </div>
                <template v-if="$slots.footer">
                    <div class="base-modal__footer">
                        <div v-if="hasScrollBottomShadow"
                             class="base-modal__shadow"
                             :class="{'base-modal__shadow--active': !isBottomReached}" />
                        <slot name="footer" />
                    </div>
                </template>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
    import {
        ref, computed, toRefs, watchEffect,
    } from 'vue';
    import { useModalEscape } from '@/js/composables/useModalEscape';
    import BaseCloseButton from '@/js/components/BaseCloseButton.vue';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { useContentScrolledObserver } from '@/js/composables/useContentScrolledObserver';
    import { useContentBottomObserver } from '@/js/composables/useContentBottomObserver';
    import { useClickOutside } from '@/js/composables/useClickOutside';

    interface Props {
        contentOverflow?: string;
        hasScrollTopShadow?: boolean;
        hasScrollBottomShadow?: boolean;
        isClosable?: boolean;
        maxWidth?: string;
        maxHeight?: string;
        padding?: string;
        showBackgroundOverlay?: boolean;
        useClickOutsideClose?: boolean;
        theme?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        contentOverflow: 'auto',
        hasScrollTopShadow: false,
        hasScrollBottomShadow: false,
        isClosable: true,
        maxWidth: '',
        maxHeight: '',
        padding: '',
        showBackgroundOverlay: true,
        useClickOutsideClose: false,
        theme: 'default-theme',
    });
    const {
        isClosable,
        maxWidth,
        maxHeight,
        padding,
        useClickOutsideClose,
    } = toRefs(props);

    const emit = defineEmits<{
        close: [];
    }>();

    enum ThemesEnum {
        DEFAULT = 'default-theme',
        VIDEO = 'video-theme',
        GAME = 'game-theme',
        NOTIFICATION = 'notification-theme',
    }

    const modalContainerRef = ref<HTMLDivElement>();
    const modalContentRef = ref<HTMLDivElement>();
    const modalContentTopRef = ref<HTMLDivElement>();
    const modalContentBottomRef = ref<HTMLDivElement>();
    const { isContentScrolled } = useContentScrolledObserver(modalContentRef, modalContentTopRef);
    const { isBottomReached } = useContentBottomObserver(modalContentRef, modalContentBottomRef);

    const handleModalClose = () => {
        if (!isClosable.value) {
            return;
        }

        emit('close');
    };

    const { isMobileOrTablet } = useDeviceSize();

    const containerStyle = computed(() => {
        const style: { [key: string]: string } = {};

        if (maxWidth.value && !isMobileOrTablet.value) {
            style['max-width'] = maxWidth.value;
        }

        if (maxHeight.value && !isMobileOrTablet.value) {
            style['max-height'] = maxHeight.value;
        }

        if (padding.value) {
            style.padding = padding.value;
        }

        return style;
    });

    useModalEscape(handleModalClose);

    watchEffect(() => {
        if (useClickOutsideClose.value) {
            useClickOutside(modalContainerRef, handleModalClose);
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";
@import "@/js/pages/builder/sass/mixins/scrollbar";
@import "@/sass/partials/z-index";

.base-modal {
    position: fixed;
    z-index: $base-modal-z-index;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    &__overlay {
        background-color: rgba($brand950, 0.95);
    }

    &__container {
        position: relative;
        display: flex;
        width: 100%;
        max-width: $laptop-breakpoint;
        max-height: 100%;
        flex: 1;
        flex-direction: column;
        padding: 32px;
        border: 1px solid $blue900;
        border-radius: 24px;
        background-color: $blue950;

        @media (max-width: $md-breakpoint-max-width) {
            height: 100vh;
        }
    }

    &__close-button {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &__content {
        overflow: auto;
        flex: 1;

        @include custom-scrollbar;

        &--top-shadow--active {
            box-shadow: inset 0 48px 48px -48px rgba($brand950, 0.7);
        }
    }

    &__bottom-ref {
        height: 1px;
    }

    .base-modal__shadow {
        position: absolute;
        right: 0;
        left: 0;

        &--active {
            height: 5px;
            border-top: 1px solid $blue900;
            box-shadow: 0 -10px 15px 0 rgba($brand950, 0.7);
        }
    }
}

@media (max-width: $md-breakpoint-max-width) {
    .base-modal {
        &__container {
            border: 1px solid $blue950;
            border-radius: unset;
        }
    }
}

.base-modal--video-theme {
    .base-modal__container {
        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: $baseBlack;

        @media (max-width: $md-breakpoint-max-width) {
            height: auto;
            margin: 0 16px;
        }
    }

    .base-modal__close-button {
        top: 8px;
        right: 8px;
    }
}

.base-modal--game-theme {
    z-index: $game-modal-z-index;

    .base-modal__container {
        @media (max-width: $md-breakpoint-max-width) {
            background-color: $brand950;
        }
    }

    .base-modal__content {
        display: flex;
        overflow: visible;
        flex-direction: column;
    }
}

.base-modal--notification-theme {
    .base-modal__container {
        background-color: $baseWhite;
    }

    .base-modal__close-button {
        display: none;
    }

    @media (max-width: $md-breakpoint-max-width) {
        display: none;
    }
}
</style>
